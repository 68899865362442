import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/Clubs/MathClub/2023/mathquiz/1.jpg';
// import p2 from 'assests/Photos/Clubs/MathClub/2023/mathquiz/2.jpg';
// import p3 from 'assests/Photos/Clubs/MathClub/2023/mathquiz/3.jpg';
// import p4 from 'assests/Photos/Clubs/MathClub/2023/mathquiz/4.jpg';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import { SidebarMath } from 'views/MathClub2023/components';


const MathQuiz = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const [config, setConfig] = useState({ base_image_archive_url: '' });
    useEffect(() => {
      fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
        .then(response => response.json())
        .then(data => setConfig(data))
        .catch(error => console.error('Error fetching config:', error));
    }, []);
    
    const p1 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/MathClub/mathquiz/1.webp`;
    const p2 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/MathClub/mathquiz/2.webp`;
    const p3 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/MathClub/mathquiz/3.webp`;
    const p4 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/MathClub/mathquiz/4.webp`;
   

    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 1,
        },
        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1,
        },
       
    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                MARVELOUS MATH QUIZ
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Classes: 1 & 2   Date: 30 AUGUST 2023
                                </Typography>

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                The students of Classes 1 and 2 participated in the Mathematics Inter-House CCA Competition conducted on Wednesday, 30 August 2023.
                                    <br></br>
                                    The major objective of this competition was to emphasize on the improvement of the intellect and knowledge of students. Two students from each house who qualified the preliminary round participated in the quiz. 
                                    <br></br>
                                    The quiz was conducted in an interactive way and the teams participated with great zeal and enthusiasm. The questions put forth to the teams were challenging and interesting which made the enthusiastic audience of classes 1 and 2 applaud when their teams answered correctly.
                                    <br></br>
                                    Quiz was very informative and enriching to the participants as well as the audience. The students felt ecstatic and enjoyed the quiz thoroughly.
                                </Typography>
                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                “All the world is a laboratory to the inquiring minds!”
                                <br />
                            </Typography>

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 300 : 200}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box marginBottom={4}>
                            <SidebarMath/>
                        </Box>
                       
                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default MathQuiz;